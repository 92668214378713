import { Skeleton, Stack } from '@mantine/core';

/**
 * Loader for ECV.
 */
const Loader: React.FC = () => {
  return (
    <Stack px="1rem">
      <Skeleton h={60} radius={8} />
      <Skeleton h={60} radius={8} />
      <Skeleton h={60} radius={8} />
    </Stack>
  );
};

export default Loader;
