import { MantineThemeOverride } from '@mantine/core';

// Buttons
import Button from 'theme/components/Buttons/Button/Button';
import ActionIcon from 'theme/components/Buttons/ActionIcon/ActionIcon';
//Inputs
import Input from 'theme/components/Inputs/Input/Input';
import InputWrapper from 'theme/components/Inputs/InputWrapper/InputWrapper';
import PasswordInput from 'theme/components/Inputs/PasswordInput/PasswordInput';
import Textarea from 'theme/components/Inputs/Textarea/Textarea';
import Select from 'theme/components/Combobox/Select/Select';
import Tabs from 'theme/components/Navigation/Tabs/Tabs';
import Badge from 'theme/components/DataDisplay/Badge/Badge';
import Accordion from 'theme/components/DataDisplay/Accordion/Accordion';
import Modal from 'theme/components/Overlays/Modal/Modal';
import Radio from 'theme/components/Inputs/Radio/Radio';
import Notification from 'theme/components/Feedback/Notification/Notification';

export const components: MantineThemeOverride['components'] = {
  // Buttons
  Button,
  ActionIcon,
  //Inputs
  Input,
  InputWrapper,
  PasswordInput,
  Textarea,
  Radio,
  //ComboBox,
  Select,
  //Navigation
  Tabs,
  //Data Display
  Accordion,
  Badge,
  //Overlays
  Modal,
  //Feedback
  Notification,
};
