/**
 * PanoramaIcon
 */
export default function PanoramaIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 600.000000 600.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M2785 5964 c-450 -44 -774 -136 -1140 -323 -787 -401 -1367 -1156
-1549 -2016 -50 -242 -60 -342 -60 -625 0 -283 10 -383 60 -625 182 -860 762
-1615 1549 -2016 317 -162 602 -252 970 -305 151 -22 568 -26 725 -6 236 30
486 90 697 167 113 42 633 294 633 308 0 22 -68 100 -114 131 -72 49 -153 70
-239 63 -57 -5 -92 -17 -198 -67 -226 -107 -453 -178 -703 -221 -154 -27 -513
-37 -678 -20 -1231 129 -2200 1098 -2329 2329 -17 165 -7 524 20 678 46 269
122 502 242 743 375 753 1073 1268 1913 1412 154 27 513 37 678 20 1231 -129
2200 -1098 2329 -2329 17 -165 7 -524 -20 -678 -46 -271 -190 -728 -311 -990
-89 -193 -184 -325 -261 -364 -55 -28 -137 -27 -197 4 -126 64 -241 170 -576
531 -341 367 -435 464 -689 706 -153 145 -237 217 -280 241 -59 31 -67 33
-167 33 -117 0 -163 -16 -235 -78 l-36 -32 188 -170 c287 -260 565 -537 910
-907 410 -441 530 -550 707 -644 108 -56 183 -77 282 -76 169 0 282 48 405
171 178 179 312 443 469 926 151 466 199 766 187 1160 -31 969 -519 1843
-1332 2383 -362 241 -766 394 -1220 463 -125 19 -523 34 -630 23z"
        />
        <path
          d="M1290 4800 c0 -19 8 -20 168 -22 l167 -3 0 -30 0 -30 -147 -6 c-180
-7 -188 -11 -188 -101 0 -55 1 -58 25 -58 23 0 25 4 25 44 0 71 10 76 166 76
130 0 136 1 159 25 14 13 25 36 25 50 0 14 -11 37 -25 50 -24 25 -27 25 -200
25 -168 0 -175 -1 -175 -20z"
        />
        <path
          d="M1711 4691 c-50 -71 -91 -132 -91 -135 0 -15 41 -4 52 14 12 18 24
20 133 20 109 0 121 -2 133 -20 11 -18 72 -30 72 -14 0 14 -186 264 -197 264
-6 0 -53 -58 -102 -129z m137 -1 l43 -60 -90 0 -90 0 41 60 c23 33 45 60 48
60 3 0 25 -27 48 -60z"
        />
        <path
          d="M2060 4685 l0 -135 25 0 25 0 0 100 c0 62 4 100 10 100 6 0 81 -43
167 -96 86 -53 163 -97 172 -98 14 -1 16 18 19 132 l3 132 -31 0 -30 0 0 -101
c0 -84 -2 -100 -14 -96 -8 3 -85 49 -170 101 -85 53 -160 96 -165 96 -7 0 -11
-47 -11 -135z"
        />
        <path
          d="M2560 4813 c-32 -11 -40 -37 -40 -128 0 -131 -2 -130 202 -130 156 0
160 1 179 24 15 19 19 39 19 108 0 76 -2 86 -25 108 -23 24 -28 25 -172 24
-81 0 -155 -3 -163 -6z m298 -122 c2 -61 -1 -87 -10 -92 -7 -5 -68 -9 -135 -9
-111 0 -123 2 -133 19 -14 27 -13 144 2 159 9 9 50 12 143 10 l130 -3 3 -84z"
        />
        <path
          d="M2960 4800 c0 -18 8 -20 148 -22 l147 -3 0 -30 0 -30 -122 -6 c-158
-7 -173 -16 -173 -99 0 -56 1 -60 24 -60 21 0 24 5 28 51 9 91 52 93 154 8 67
-54 78 -60 114 -57 l39 3 -67 55 -67 55 54 5 c61 7 81 25 81 76 0 23 -8 39
-26 53 -24 19 -40 21 -180 21 -147 0 -154 -1 -154 -20z"
        />
        <path
          d="M3523 4783 c-16 -21 -60 -82 -97 -135 -65 -94 -66 -98 -42 -98 15 0
30 8 36 20 10 18 21 20 127 20 104 0 119 -2 139 -21 14 -13 32 -19 48 -17 26
3 24 6 -66 132 -51 71 -98 131 -104 133 -7 2 -25 -13 -41 -34z m68 -89 c22
-30 39 -58 36 -60 -2 -3 -41 -4 -86 -2 l-81 3 41 58 c22 31 42 57 44 57 3 0
23 -25 46 -56z"
        />
        <path
          d="M3807 4813 c-4 -3 -7 -64 -7 -135 l0 -128 30 0 30 0 0 100 c0 55 3
100 8 100 4 0 41 -25 82 -55 41 -30 78 -55 82 -55 5 0 42 25 83 55 40 30 76
55 79 55 3 0 7 -44 8 -97 l3 -98 28 -3 27 -3 0 135 c0 106 -3 136 -13 136 -7
0 -56 -31 -109 -70 -53 -38 -101 -70 -106 -70 -5 0 -53 32 -106 70 -96 70
-107 76 -119 63z"
        />
        <path
          d="M4463 4783 c-16 -21 -60 -82 -97 -135 -64 -93 -66 -98 -42 -98 15 0
30 8 36 20 10 18 21 20 127 20 104 0 119 -2 139 -21 14 -13 32 -19 49 -17 26
3 25 5 -67 132 -51 71 -98 131 -104 133 -7 2 -25 -13 -41 -34z m68 -89 c22
-30 39 -58 36 -60 -2 -3 -41 -4 -86 -2 l-81 3 41 58 c22 31 42 57 44 57 3 0
23 -25 46 -56z"
        />
        <path
          d="M1490 4425 c-134 -27 -261 -93 -329 -170 -41 -47 -81 -130 -81 -167
l0 -28 1468 0 c905 0 1493 -4 1535 -10 177 -26 270 -136 283 -338 11 -159 -34
-291 -118 -352 l-40 -30 -61 48 c-33 26 -66 57 -74 69 -8 12 -30 27 -48 33
-20 7 -42 25 -54 44 -19 30 -74 75 -144 119 -28 17 -30 17 -63 -3 -19 -12 -34
-25 -34 -29 0 -14 -88 -99 -109 -106 -12 -4 -41 -17 -64 -31 -53 -30 -82 -30
-132 1 -22 13 -53 31 -70 41 -16 9 -55 39 -85 68 -47 43 -46 42 5 -9 34 -33
83 -73 110 -88 28 -16 57 -33 65 -37 12 -7 11 -12 -5 -30 -20 -22 -72 -48
-111 -56 -28 -5 -123 55 -124 78 0 23 -6 26 -90 34 -83 8 -126 8 -182 1 -23
-2 -35 0 -31 5 3 6 -3 7 -16 3 -30 -9 -26 2 7 19 17 9 34 11 42 6 7 -4 24 -4
38 1 14 5 27 7 29 5 9 -9 45 32 38 43 -3 6 -10 9 -14 6 -5 -2 -11 18 -15 45
-4 27 -13 55 -20 63 -7 7 -14 26 -14 42 -1 36 -6 64 -16 88 -13 29 2 32 28 6
29 -30 80 -72 126 -106 l35 -25 -32 28 c-76 68 -130 121 -135 134 -4 12 -9 12
-37 0 -35 -14 -131 -85 -143 -106 -5 -6 -33 -28 -63 -49 -30 -20 -55 -40 -55
-46 0 -15 -141 -159 -187 -191 -36 -24 -44 -27 -49 -14 -3 9 -18 16 -33 16
-14 0 -45 11 -68 24 -23 14 -52 27 -64 31 -21 7 -109 92 -109 106 0 4 -16 17
-35 29 -33 20 -35 21 -62 3 -68 -43 -131 -95 -144 -119 -8 -15 -33 -35 -56
-45 -24 -10 -43 -23 -43 -28 0 -5 -26 -31 -57 -56 -32 -26 -69 -60 -82 -77
l-24 -31 24 13 c58 30 139 86 144 100 3 8 30 28 60 44 39 20 60 39 72 64 9 19
25 37 34 39 10 3 21 14 24 24 6 18 51 59 65 59 4 0 10 -8 14 -17 3 -10 11 -25
17 -34 20 -27 -16 -106 -68 -153 -23 -22 -43 -43 -43 -47 0 -4 -12 -26 -27
-48 -20 -31 -33 -41 -54 -41 -14 0 -42 -3 -60 -6 l-34 -5 26 17 c14 9 32 26
38 37 11 17 11 19 -4 13 -9 -3 -29 -9 -43 -13 -24 -6 -26 -5 -13 11 11 12 1
11 -39 -8 -55 -26 -76 -46 -58 -57 5 -4 -12 -10 -38 -13 -27 -4 -60 -10 -74
-13 -25 -5 -25 -5 -5 11 11 9 -2 5 -30 -8 -27 -14 -90 -44 -139 -66 -156 -70
-251 -163 -319 -310 -61 -133 -67 -196 -67 -689 l0 -444 85 6 c154 11 324 87
395 177 63 79 64 87 71 420 6 344 10 364 80 444 22 25 65 57 97 73 l57 28
1170 6 c1277 6 1232 4 1414 65 181 60 317 174 389 324 116 244 105 600 -26
814 -44 74 -131 160 -206 205 -31 19 -101 49 -154 67 -191 65 -170 64 -1603
63 -1025 -1 -1308 -4 -1359 -14z m326 -89 c33 -46 4 -96 -55 -96 l-36 0 50
-45 50 -44 -33 -1 c-25 0 -47 11 -89 45 -75 62 -113 58 -113 -12 0 -26 -4 -33
-20 -33 -17 0 -20 7 -20 44 0 61 15 70 129 76 90 5 96 6 99 28 3 22 2 22 -112
22 -111 0 -116 1 -116 21 0 20 4 21 126 17 111 -3 127 -5 140 -22z m349 4 c0
-12 -23 -16 -130 -20 -122 -5 -130 -6 -130 -25 0 -19 8 -20 133 -23 108 -2
132 -5 132 -17 0 -12 -22 -15 -124 -15 -129 0 -154 -8 -140 -44 5 -14 25 -16
135 -16 108 0 129 -2 129 -15 0 -13 -22 -15 -138 -15 -150 0 -174 7 -173 51 2
57 2 70 0 96 -1 17 7 33 21 45 20 16 39 18 154 16 109 -3 131 -6 131 -18z
m365 0 c0 -18 -7 -20 -70 -20 l-70 0 0 -65 c0 -92 -15 -105 -120 -105 -63 0
-80 3 -80 15 0 11 17 15 73 17 l72 3 3 55 c5 111 13 118 125 119 61 1 67 -1
67 -19z m80 -85 l0 -105 -25 0 -25 0 0 105 0 105 25 0 25 0 0 -105z m317 79
c23 -23 25 -31 21 -91 -6 -91 -10 -93 -180 -93 -115 0 -138 2 -138 15 0 12 24
15 133 17 l132 3 0 65 0 65 -125 3 c-68 1 -129 7 -133 11 -24 24 9 31 134 31
126 0 131 -1 156 -26z m353 6 c0 -19 -7 -20 -131 -20 -130 0 -130 0 -127 -22
3 -22 7 -23 131 -26 104 -2 127 -5 127 -17 0 -12 -21 -15 -117 -15 -125 0
-147 -6 -141 -36 3 -17 15 -19 131 -22 104 -2 127 -5 127 -17 0 -13 -23 -15
-140 -15 -139 0 -140 0 -157 26 -13 20 -14 32 -6 53 6 18 6 32 0 42 -15 23 -1
64 25 77 13 6 80 11 151 11 121 1 127 0 127 -19z m215 -84 l90 -55 3 80 c3 76
4 80 25 77 21 -3 22 -9 25 -101 2 -77 0 -98 -12 -103 -7 -3 -69 29 -136 70
-67 42 -123 76 -126 76 -2 0 -4 -34 -4 -75 0 -73 -1 -75 -25 -75 -25 0 -25 0
-25 98 0 54 3 102 7 105 7 7 33 -7 178 -97z m475 84 c0 -18 -8 -20 -127 -22
l-128 -3 0 -65 0 -65 128 -3 c104 -2 127 -5 127 -17 0 -13 -23 -15 -135 -15
-166 0 -175 5 -175 103 0 100 12 106 183 107 120 0 127 -1 127 -20z m340 0 c0
-19 -7 -20 -131 -20 -130 0 -130 0 -127 -22 3 -22 7 -23 131 -26 104 -2 127
-5 127 -17 0 -12 -21 -15 -117 -15 -125 0 -147 -6 -141 -36 3 -17 15 -19 131
-22 104 -2 127 -5 127 -17 0 -23 -266 -22 -292 2 -19 17 -23 49 -9 72 6 9 5
21 -1 31 -15 24 -1 69 25 80 12 5 79 9 150 9 121 1 127 0 127 -19z m-467 -730
c14 -11 28 -29 32 -39 3 -10 14 -21 24 -24 9 -2 25 -20 34 -39 12 -25 33 -44
72 -64 30 -16 57 -36 60 -44 3 -8 28 -30 56 -48 28 -18 49 -36 46 -39 -3 -3
-31 7 -63 21 -36 17 -52 21 -44 11 15 -18 3 -19 -36 -5 -37 14 -39 8 -8 -23
l27 -27 -50 0 c-47 0 -51 2 -76 41 -15 22 -27 44 -27 48 0 4 -20 25 -43 47
-52 47 -88 126 -68 153 6 9 14 24 17 34 8 22 16 22 47 -3z m-1120 -120 c-14
-27 -33 -55 -42 -62 -10 -8 -8 -3 4 12 l20 24 -25 -16 c-14 -8 -25 -20 -25
-26 0 -6 -4 -14 -9 -19 -5 -5 -6 -2 -1 6 5 9 3 12 -6 9 -8 -3 -13 -11 -11 -17
1 -7 -2 -10 -7 -7 -5 3 -14 -1 -21 -9 -16 -19 -38 -19 -81 -1 -30 13 -31 15
-14 21 36 11 119 54 180 94 33 22 61 40 62 40 1 1 -10 -21 -24 -49z m257 46
c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-809 -73 c13
-16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m862 -9 c11 11 24 15
38 11 12 -4 27 -8 34 -9 7 -1 41 -30 74 -65 59 -61 61 -65 45 -82 -24 -26
-174 -28 -195 -2 -13 15 -12 16 9 10 12 -4 32 -10 45 -13 21 -5 20 -4 -3 10
-14 8 -33 15 -42 16 -17 0 -44 37 -35 47 3 2 13 0 24 -6 10 -6 34 -18 53 -27
34 -16 34 -16 -14 30 -26 25 -52 46 -56 46 -12 0 -20 28 -13 48 6 14 8 13 13
-7 5 -23 6 -23 23 -7z m727 22 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13
16 21 21 21 13z m-1511 -50 c10 -12 9 -14 -5 -12 -10 2 -20 8 -22 14 -5 17 13
15 27 -2z m107 -35 c25 -30 26 -33 6 -22 -12 6 -22 9 -22 6 0 -2 -8 2 -17 10
-10 8 -27 22 -38 31 -16 13 -17 15 -2 10 9 -3 17 -1 17 5 0 15 24 -2 56 -40z
m1277 40 c19 9 18 7 -3 -11 -51 -43 -60 -50 -60 -45 0 3 -10 0 -22 -6 -22 -11
-22 -11 2 17 37 43 54 56 58 44 3 -7 11 -6 25 1z m55 -3 c-2 -6 -12 -12 -22
-14 -14 -2 -15 0 -5 12 14 17 32 19 27 2z m-1444 -38 c30 -17 57 -37 60 -45 3
-8 14 -15 25 -15 18 0 75 -39 57 -40 -18 0 -116 51 -138 73 -13 12 -34 30 -48
39 -41 28 -14 20 44 -12z m1471 15 c-30 -22 -85 -67 -108 -90 -11 -11 -17 -14
-13 -7 10 16 -6 15 -30 -3 -30 -23 -54 -18 -25 5 13 11 32 20 42 20 10 0 23 9
29 19 5 11 20 23 32 27 13 4 34 15 47 25 13 11 29 19 35 19 6 0 2 -7 -9 -15z
m-675 -21 c0 -2 -9 0 -20 6 -11 6 -20 13 -20 16 0 2 9 0 20 -6 11 -6 20 -13
20 -16z m-584 -55 c4 -8 1 -8 -14 0 -12 6 -24 16 -28 22 -4 8 -1 8 14 0 12 -6
24 -16 28 -22z m1039 21 c-3 -5 -16 -15 -28 -21 -21 -10 -21 -9 2 10 25 23 36
27 26 11z m-1665 -60 c0 -5 -12 -10 -27 -9 l-28 1 25 8 c14 4 26 8 28 9 1 1 2
-3 2 -9z m967 3 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z"
        />
        <path
          d="M3876 3475 c10 -8 15 -15 10 -15 -8 0 8 -13 89 -75 28 -22 41 -18 20
5 -10 11 -25 20 -32 20 -18 0 -12 22 7 28 7 3 10 0 7 -5 -4 -6 6 -16 21 -22
l27 -12 -27 27 c-22 22 -30 25 -41 16 -17 -15 -50 -5 -40 12 3 6 3 8 -2 4 -5
-5 -14 1 -22 12 -8 11 -19 20 -25 20 -7 0 -3 -7 8 -15z"
        />
        <path
          d="M2300 3415 c0 -2 10 -10 23 -16 20 -11 21 -11 8 4 -13 16 -31 23 -31
12z"
        />
        <path
          d="M3579 3403 c-13 -15 -12 -15 9 -4 23 12 28 21 13 21 -5 0 -15 -7 -22
-17z"
        />
        <path
          d="M3175 3660 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"
        />
        <path
          d="M2017 3470 c-8 -11 -17 -18 -20 -15 -4 3 -5 2 -2 -1 5 -8 -39 -44
-53 -44 -12 0 -37 -29 -30 -35 2 -3 13 2 24 10 80 62 96 75 88 75 -5 0 0 7 10
15 11 8 15 15 8 15 -6 0 -17 -9 -25 -20z"
        />
        <path
          d="M1909 3424 l-24 -26 27 13 c15 6 25 17 21 22 -4 6 0 8 11 4 10 -4 15
-3 11 3 -10 16 -20 12 -46 -16z"
        />
      </g>
    </svg>
  );
}
