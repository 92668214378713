/**
 * FavoriteDisabledIcon component.
 */
export function FavoriteDisabledIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <circle cx="16" cy="16" r="16" fill="#EAEDF0" />
      <path
        d="M15.233 9.05336C15.4744 8.27649 16.5255 8.27648 16.7669 9.05336L17.9916 12.9944C18.0995 13.3418 18.4091 13.577 18.7585 13.577H22.7214C23.5026 13.577 23.8274 14.6223 23.1954 15.1024L19.9894 17.5381C19.7067 17.7529 19.5884 18.1335 19.6964 18.4809L20.921 22.4219C21.1624 23.1988 20.3121 23.8448 19.6801 23.3647L16.474 20.929C16.1914 20.7143 15.8086 20.7143 15.526 20.929L12.3199 23.3647C11.6879 23.8448 10.8376 23.1988 11.079 22.4219L12.3036 18.4809C12.4115 18.1335 12.2933 17.7529 12.0106 17.5381L8.80454 15.1024C8.17255 14.6223 8.49735 13.577 9.27854 13.577H13.2415C13.5908 13.577 13.9005 13.3418 14.0084 12.9944L15.233 9.05336Z"
        fill="#B9C0CD"
      />
    </svg>
  );
}
