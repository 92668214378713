import { Card, Group, Stack } from '@mantine/core';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';
import P4Regular from 'theme/components/Typography/P4Regular';

interface SensorInfoProps {
  sensor: {
    id?: number;
    name?: string;
    mainBox?: { icon?: string; value?: number; unit?: string; headline?: string; subHeadline?: string };
  };
  lang: string;
}

/**
 * A component that displays a Chastia sensor's name, main box value, unit, headline and sub-headline.
 * @param {SensorInfoProps} props
 * @param {object} props.sensor - The Chastia sensor to display.
 * @param {string} props.lang - The language to display numbers in.
 * @returns {JSX.Element} A component that displays the sensor's information.
 */
export default function SensorInfo({ sensor, lang }: SensorInfoProps) {
  return (
    <Card p={20} radius={10}>
      <Stack gap={20}>
        <Group align="center" justify="center">
          <img src={sensor?.mainBox?.icon} alt={sensor?.name} width={46} />
          <span>
            <H2SemiBold>
              {new Intl.NumberFormat(lang, { style: 'decimal' }).format(sensor?.mainBox?.value ?? 0)}{' '}
              {sensor?.mainBox?.unit}
            </H2SemiBold>
          </span>
        </Group>
        <Stack gap={4} ta="center">
          <P4Regular color="gray.7">
            <span>{sensor?.mainBox?.headline}</span>
          </P4Regular>
          <P4Regular color="gray.7">
            <span>{sensor?.mainBox?.subHeadline}</span>
          </P4Regular>
        </Stack>
      </Stack>
    </Card>
  );
}
