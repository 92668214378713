import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P4Regular as P4RegularStyle } from 'theme/typography';

interface P4RegularProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P4Regular typography style.
 */
const P4Regular: React.FC<P4RegularProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P4RegularStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P4Regular;
