import React, { useEffect, useState } from 'react';
import { Button, Flex, Input, Stack } from '@mantine/core';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';
import { IconChevronRight, IconX } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { ECV_PAGE_PATH } from 'routes/paths';
import { useForm } from '@mantine/form';
import { GetLicensePlatesResponse } from 'api/actions/get-license-plates/get-license-plates-response';
import { useApi } from 'api/api-context';
import panic from 'panic';
import { notifications } from '@mantine/notifications';

type LicensePlateArray = NonNullable<GetLicensePlatesResponse['licensePlates']>;

/**
 * EcvEdit page
 */
const EcvEdit: React.FC = () => {
  const { _t } = useLang();
  const navigate = useNavigate();
  const { getAction } = useApi();
  const [licensePlates, setLicensePlates] = useState<LicensePlateArray>([]);
  const [, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const item = licensePlates.find((licensePlate) => licensePlate.id === Number(id));

  const form = useForm({
    initialValues: {
      code: item?.code,
      note: item?.note,
    },
  });

  useEffect(() => {
    const getLicensePlates = getAction('GetLicensePlates');
    getLicensePlates()
      .then(({ licensePlates }) => {
        setLicensePlates(licensePlates || []);
      })
      .catch(panic)
      .finally(() => setLoading(false));
  }, [getAction, id]);

  /**
   * Handle back button
   */
  const handleClick = () => {
    navigate(ECV_PAGE_PATH.original);
  };

  return (
    <SimpleLayout>
      <form
        onSubmit={form.onSubmit((data) => {
          const updateLicensePlate = getAction('UpdateLicensePlate');
          setLoading(true);
          updateLicensePlate({
            parameters: { licensePlateId: Number(id) },
            payload: { code: String(data.code || item?.code), note: data.note },
          })
            .then(() => {
              navigate(ECV_PAGE_PATH.original);
              notifications.show({
                title: _t('Your request has been successfully entered.'),
                message: '',
                variant: 'green',
              });
            })
            .catch(panic)
            .finally(() => setLoading(false));
        })}
      >
        <Stack justify="space-between" h="85vh">
          <Flex p="1rem" gap="1.5rem" direction="column">
            <H2SemiBold color={theme.black}>{_t('Edit registration number')}</H2SemiBold>
            <Input.Wrapper label={_t('Registration number of your vehicle (without spaces)')} required>
              <Input
                variant="primary"
                placeholder={_t('Registration number of your vehicle')}
                defaultValue={item?.code}
                required
                {...form.getInputProps('code')}
              />
            </Input.Wrapper>
            <Input.Wrapper label={_t('Note to the vehicle registration number (optional)')}>
              <Input
                variant="primary"
                placeholder={_t('Note')}
                defaultValue={item?.note}
                {...form.getInputProps('note')}
              />
            </Input.Wrapper>
          </Flex>
          <Flex
            justify="space-between"
            gap="1rem"
            px="1rem"
            w="100%"
            style={{
              position: 'fixed',
              bottom: '2.5rem',
            }}
          >
            <Button variant="secondary" w="100%" size="lg" leftSection={<IconX size="1.5rem" />} onClick={handleClick}>
              {_t('Cancel')}
            </Button>
            <Button
              variant="primary"
              w="100%"
              size="lg"
              type="submit"
              rightSection={<IconChevronRight size="1.5rem" />}
            >
              {_t('Edit VRN')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </SimpleLayout>
  );
};

export default EcvEdit;
