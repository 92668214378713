import { Modal, Flex, Button } from '@mantine/core';
import { IconChevronRight, IconX } from '@tabler/icons-react';
import React from 'react';

interface ModalConfirmationProps {
  requestModalOpened: boolean;
  handleCloseRequestModal: () => void;
  handleSubmit: () => void;
  title?: string;
  cancelButtonText?: string;
  submitButtonText?: string;
}

/**
 * ModalConfirmation component
 */
const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  requestModalOpened,
  handleCloseRequestModal,
  handleSubmit,
  title,
  cancelButtonText,
  submitButtonText,
}) => {
  return (
    <Modal
      variant="request"
      opened={requestModalOpened}
      onClose={handleCloseRequestModal}
      closeOnClickOutside
      title={title}
      transitionProps={{ transition: 'slide-up', duration: 400 }}
    >
      <Flex justify="space-between" gap="1rem">
        <Button
          variant="secondary"
          w="100%"
          size="lg"
          leftSection={<IconX size="1.5rem" />}
          onClick={handleCloseRequestModal}
        >
          {cancelButtonText}
        </Button>
        <Button
          variant="primary"
          w="100%"
          size="lg"
          rightSection={<IconChevronRight size="1.5rem" />}
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
      </Flex>
    </Modal>
  );
};

export default ModalConfirmation;
