/**
 * PlusIcon component.
 */
export function PlusIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill="#EBEEF2" />
      <path d="M23.9772 17V31" stroke="#0D0E14" stroke-width="2" stroke-miterlimit="10" />
      <path d="M17 23.9771H31" stroke="#0D0E14" stroke-width="2" stroke-miterlimit="10" />
    </svg>
  );
}
