/**
 * FavoriteEnabledIcon component.
 */
export function FavoriteEnabledIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <circle cx="17" cy="17" r="16.5" fill="#FFF0DF" stroke="white" />
      <path
        d="M16.233 10.0534C16.4744 9.27649 17.5255 9.27648 17.7669 10.0534L18.9916 13.9944C19.0995 14.3418 19.4091 14.577 19.7585 14.577H23.7214C24.5026 14.577 24.8274 15.6223 24.1954 16.1024L20.9894 18.5381C20.7067 18.7529 20.5884 19.1335 20.6964 19.4809L21.921 23.4219C22.1624 24.1988 21.3121 24.8448 20.6801 24.3647L17.474 21.929C17.1914 21.7143 16.8086 21.7143 16.526 21.929L13.3199 24.3647C12.6879 24.8448 11.8376 24.1988 12.079 23.4219L13.3036 19.4809C13.4115 19.1335 13.2933 18.7529 13.0106 18.5381L9.80454 16.1024C9.17255 15.6223 9.49735 14.577 10.2785 14.577H14.2415C14.5908 14.577 14.9005 14.3418 15.0084 13.9944L16.233 10.0534Z"
        fill="#FFB660"
      />
    </svg>
  );
}
