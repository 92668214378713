import { Stack, Card, Group, Switch } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { NumberInput, SelectInput, SwitchInput } from 'api/actions/get-sensor/get-sensor-response';
import { Link } from 'react-router-dom';
import theme from 'theme';
import P1Medium from 'theme/components/Typography/P1Medium';
import P2Medium from 'theme/components/Typography/P2Medium';
import P2Regular from 'theme/components/Typography/P2Regular';

interface SensorAlertsProps {
  sensor: {
    id?: number;
    name?: string;
    alerts?: {
      active?: boolean;
      title?: string;
      options?: (SwitchInput | SelectInput | NumberInput)[];
    }[];
  };
  _t: (msgid: string) => string;
}

/**
 * A component that displays a list of sensor alerts and their statuses.
 * @prop {object} sensor - The sensor object containing the alerts.
 * @prop {function} _t - A translation function.
 * @returns {JSX.Element} A component that displays the sensor alerts.
 */
export default function SensorAlerts({ sensor, _t }: SensorAlertsProps) {
  return (
    <Stack gap={16}>
      <P2Medium color="gray.7">Upozornenia</P2Medium>
      <Card py={8} px={16} radius={10}>
        <Stack gap={8} py={8}>
          {sensor?.alerts?.map((alert) => (
            <Group key={alert.title} gap={16}>
              <Stack gap={0} w="calc(100% - 80px)">
                <P1Medium color="gray.12">{alert.title}</P1Medium>
                <P2Regular color="gray.7">{alert.active ? '{{ params }}' : _t('Disabled')}</P2Regular>
              </Stack>
              <Switch
                checked={alert.active}
                color="#551FFF"
                size="lg"
                styles={{ track: { width: '56px', height: '32px' } }}
              />
            </Group>
          ))}
        </Stack>
        <Link to={'/sensors/sensor/edit/' + sensor?.id} style={{ textDecoration: 'none' }}>
          <Stack gap={8} mt={8} py={8}>
            {sensor?.alerts?.map((alert) => (
              <Group key={alert.title} gap={16}>
                <Stack gap={0} w="calc(100% - 40px)">
                  <P1Medium color="gray.12">{alert.title}</P1Medium>
                  <P2Regular color="gray.7">{alert.active ? '{{ params }}' : _t('Disabled')}</P2Regular>
                </Stack>
                <IconChevronRight size={24} color={theme.colors?.gray?.[7]} />
              </Group>
            ))}
          </Stack>
        </Link>
      </Card>
    </Stack>
  );
}
