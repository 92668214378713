import { Center, Flex, Loader, Stack } from '@mantine/core';
import { GetSensorResponse } from 'api/actions/get-sensor/get-sensor-response';
import { useApi } from 'api/api-context';
import { BackIcon } from 'components/icons/BackIcon';
import { FavoriteDisabledIcon } from 'components/icons/FavoriteDisabledIcon';
import { FavoriteEnabledIcon } from 'components/icons/FavoriteEnabledIcon';
import useLang from 'lang';
import panic from 'panic';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';
import H5SemiBold from 'theme/components/Typography/H5SemiBold';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
} from 'chart.js';
import SensorInfo from 'components/SensorInfo';
import SensorAlerts from 'components/SensorAlerts';
import SensorStatistics from 'components/SensorStatistics';

// Registrácia potrebných komponentov pre Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Sensor = GetSensorResponse['sensor'];

/**
 * Detail of a sensor.
 */
export default function SensorDetail() {
  const { lang, _t } = useLang();
  const { getAction } = useApi();
  const [sensor, setSensor] = useState<Sensor | null>(null);
  const { sensorId: sensorIdRaw = '' } = useParams();
  const sensorId = useMemo(() => parseInt(sensorIdRaw), [sensorIdRaw]);
  const [isFavorite, setIsFavorite] = useState(false); // Stav pre sledovanie, či je senzor pridaný medzi obľúbené
  const [selectedTitle, setSelectedTitle] = useState<string | null>('Tento týždeň');

  useEffect(() => {
    if (sensorId) {
      const getSensor = getAction('GetSensor');

      getSensor({ parameters: { sensorId } })
        .then(({ sensor }) => setSensor(sensor))
        .catch(panic);
    }
  }, [sensorId]);

  /**
   * Handle a click on the favorite button.
   *
   * Toggles the favorite status of the sensor and shows a notification.
   */
  const handleFavoriteClick = () => {
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);

    alert(!isFavorite ? chartData : 'Senzor odstránený z obľúbených');
  };

  // Function to calculate average value based on selected title
  const calculateAverageValue = useMemo(() => {
    if (!sensor || !sensor.chart || !sensor.chart.views) return 0;

    const chartView = sensor.chart.views.find((view) => view.title === selectedTitle);
    if (!chartView || !chartView.data || chartView.data.length === 0) return 0;

    const total = chartView.data.reduce((acc, item) => acc + (item.value ?? 0), 0);
    return (total / chartView.data.length).toFixed(2); // Return average value rounded to two decimal places
  }, [sensor, selectedTitle]);

  /**
   * Get the range for a selected title.
   *
   * @param title The title of the chart view
   * @returns The range of the chart view if found, otherwise an empty string
   */
  const getRangeForSelectedTitle = (title: string) => {
    if (!sensor || !sensor.chart || !sensor.chart.views) return '';

    const chartView = sensor.chart.views.find((view) => view.title === title);
    return chartView ? chartView.range : '';
  };

  const chartData = useMemo(() => {
    if (!sensor || !sensor.chart || !sensor.chart.views) {
      return null;
    }

    const chartViews = sensor.chart.views.find((view) => view.title === selectedTitle);
    if (!chartViews || !chartViews.data) {
      return null;
    }

    const dataValues = chartViews.data.map((item) => item.value ?? 0);

    return {
      labels: chartViews.data.map((item) => item.labelShort),
      datasets: [
        {
          barPercentage: 0.6,
          label: 'Blue Bars',
          data: dataValues,
          backgroundColor: '#551FFF',
          hoverBackgroundColor: '#1F212A',
          borderRadius: 5,
          borderSkipped: false,
        },
      ],
    };
  }, [sensor, selectedTitle]);

  const backgroundBar = {
    id: 'backgroundBar',
    beforeDraw: (chart: ChartJS<'bar'>) => {
      const {
        data,
        ctx,
        chartArea: { top, width, height },
        scales: { x },
      } = chart;
      const length = data?.labels?.length ?? 0;
      ctx.save();
      const segment = width / length;
      const barWidth = segment * 0.49;
      ctx.fillStyle = '#EEF1F5';
      ctx.beginPath();
      for (let i = 0; i < length; i++) {
        ctx.roundRect(x.getPixelForValue(i) - barWidth / 2, top, barWidth, height, 5);
      }
      ctx.fill();
    },
  };

  const chartOptions = {
    scales: {
      x: {
        ticks: {
          color: theme.colors?.gray?.[7],
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        border: {
          display: false,
        },
      },

      y: {
        ticks: {
          color: theme.colors?.gray?.[7],
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context: TooltipItem<'bar'>[]) {
            if (!sensor || !sensor.chart || !sensor.chart.views) return '';
            const chartViews = sensor.chart.views.find((view) => view.title === selectedTitle);
            if (!chartViews || !chartViews.data || context.length === 0) return '';
            const index = context[0].dataIndex;
            const data = chartViews.data[index];
            const value = data.value ?? 0;
            const suffix = sensor.mainBox?.unit ?? '';
            return `${value} ${suffix}`;
          },

          label: function (context: TooltipItem<'bar'>) {
            if (!sensor || !sensor.chart || !sensor.chart.views) return '';
            const chartViews = sensor.chart.views.find((view) => view.title === selectedTitle);
            if (!chartViews || !chartViews.data) return '';
            const index = context.dataIndex;
            const data = chartViews.data[index];
            const date = data.labelLong ?? 'N/A';
            return `${date}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  if (!sensor) {
    return (
      <Center style={{ height: '100vh' }}>
        <Flex direction="column" align="center" gap="1rem">
          <Loader size="lg" color="#551FFF" />
          <H2SemiBold>{_t('Načítavanie senzoru')}</H2SemiBold>
        </Flex>
      </Center>
    );
  }

  const titles = sensor.chart?.views?.map((view) => view.title) || [];

  return (
    <Stack bg="gray.0" py={20} px={16} mih="100vh">
      <Flex justify="space-between" align="center" mb={6} gap={16}>
        <span onClick={() => window.history.back()} style={{ cursor: 'pointer' }}>
          <BackIcon />
        </span>

        <span style={{ textAlign: 'center' }}>
          <H5SemiBold>{sensor?.name}</H5SemiBold>
        </span>
        {isFavorite ? (
          <span onClick={handleFavoriteClick}>
            <FavoriteEnabledIcon />
          </span>
        ) : (
          <span onClick={handleFavoriteClick}>
            <FavoriteDisabledIcon />
          </span>
        )}
      </Flex>
      <SensorInfo sensor={sensor} lang={lang} />

      <SensorAlerts sensor={sensor} _t={_t} />

      <SensorStatistics
        calculateAverageValue={calculateAverageValue}
        sensor={sensor}
        getRangeForSelectedTitle={getRangeForSelectedTitle}
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
        titles={titles}
        chartData={chartData}
        chartOptions={chartOptions}
        backgroundBar={backgroundBar}
      />
    </Stack>
  );
}
