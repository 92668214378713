export const GRAMMAR_FORM_ONE = 'one';
export const GRAMMAR_FORM_FEW = 'few';
export const GRAMMAR_FORM_MANY = 'many';
export const GRAMMAR_FORM_OTHER = 'other';

/**
 * Enum of the grammar forms.
 */
export type GrammarForm =
  | typeof GRAMMAR_FORM_ONE
  | typeof GRAMMAR_FORM_FEW
  | typeof GRAMMAR_FORM_MANY
  | typeof GRAMMAR_FORM_OTHER;

/**
 * Computes the identifier of the grammar form based on the given count. The
 * identifier serves as the index of the translation when an array of
 * translations is used.
 */
export function determineGrammarForm(count: number, lang: string): GrammarForm {
  switch (lang) {
    case 'cs':
      return count === 1 ? GRAMMAR_FORM_ONE : count >= 2 && count <= 4 ? GRAMMAR_FORM_FEW : GRAMMAR_FORM_OTHER;
    case 'pl':
      return count === 1 ? GRAMMAR_FORM_ONE : count >= 2 && count <= 4 ? GRAMMAR_FORM_FEW : GRAMMAR_FORM_MANY;
    default:
      return GRAMMAR_FORM_ONE;
  }
}
