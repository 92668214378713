import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { H5SemiBold as H5SemiBoldStyle } from 'theme/typography';

interface H5SemiBoldProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the H5SemiBold typography style.
 */
const H5SemiBold: React.FC<H5SemiBoldProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...H5SemiBoldStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default H5SemiBold;
