import { Badge } from '@mantine/core';
import classNames from 'theme/components/DataDisplay/Badge/Badge.module.css';

export default Badge.extend({
  classNames,
  defaultProps: {
    variant: 'blue',
    size: 'lg',
    radius: 'md',
  },
});
