import React from 'react';
import { Box, Flex, Badge } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import theme from 'theme';
import P1SemiBold from 'theme/components/Typography/P1SemiBold';
import P2Regular from 'theme/components/Typography/P2Regular';

interface HelpDeskItemProps {
  title: string;
  size: string;
  badges: Array<{ variant: string; text: string }>;
}
/**
 * HelpDeskItem
 */
const HelpDeskItem: React.FC<HelpDeskItemProps> = ({ title, size, badges }) => (
  <Flex
    direction="row"
    justify="space-between"
    align="center"
    bg={theme.white}
    p="1rem"
    style={{ borderRadius: '0.625rem' }}
    mb="0.5rem"
  >
    <Box>
      <Flex gap="0.5rem" maw="15.625rem" wrap="wrap">
        {badges.map((badge, index) => (
          <Badge key={index} variant={badge.variant}>
            {badge.text}
          </Badge>
        ))}
      </Flex>
      <P1SemiBold color={theme.colors?.gray?.[12]} mt="0.5rem">
        {title}
      </P1SemiBold>
      <P2Regular color={theme.colors?.gray?.[9]} mt="0.25rem">
        {size}
      </P2Regular>
    </Box>
    <IconChevronRight size="1.5rem" color={theme.colors?.gray?.[7]} />
  </Flex>
);

export default HelpDeskItem;
