import { Box, Flex } from '@mantine/core';
import { Icon } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import theme from 'theme';
import P1SemiBold from 'theme/components/Typography/P1SemiBold';
import P2Regular from 'theme/components/Typography/P2Regular';

interface MenuItemProps {
  to: string;
  icon?: Icon;
  title: string;
  description?: string;
  iconRight: Icon;
  onClick?: () => void;
}

/**
 * MenuItem component
 */
const MenuItem = ({ to, icon: Icon, title, description, iconRight: IconRight, onClick }: MenuItemProps) => (
  <Box px="1rem">
    <Link to={to} style={{ textDecoration: 'none' }} onClick={onClick}>
      <Flex direction="row" align="center" p="1rem" mb="0.5rem" bg={theme.white} style={{ borderRadius: '10px' }}>
        {Icon && <Icon size="2rem" color={theme.colors?.gray?.[12]} />}
        <Box w="100%" ml="0.8125rem" mih="2.75rem" style={{ alignContent: 'center' }}>
          <P1SemiBold color={theme.colors?.gray?.[12]}>{title}</P1SemiBold>
          {description && <P2Regular color={theme.colors?.gray?.[10]}>{description}</P2Regular>}
        </Box>
        <IconRight size="1.5rem" color={theme.colors?.gray?.[7]} />
      </Flex>
    </Link>
  </Box>
);

export default MenuItem;
