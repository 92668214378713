/**
 * BackIcon component.
 */
export function BackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11.41 16.59L6.83 12L11.41 7.41L10 6L4 12L10 18L11.41 16.59Z" fill="#081330" />
      <rect x="6" y="11" width="14" height="2" fill="#081330" />
    </svg>
  );
}
