import axios from 'axios';
import { ApiContext } from 'api/api-context';
import { ActionName, actions, GetActionMap } from 'api/actions/actions';
import useLocalStorage from 'hooks/use-local-storage';
import { REST_API_TIMEOUT, REST_API_URL } from 'env';
import { useCallback, useEffect, useMemo } from 'react';
import useLang from 'lang';
import { TOKEN } from 'api/token';
import { useSearchParams } from 'react-router-dom';

/**
 * The session provider is used to provide the session context.
 */
export default function ApiProvider({ children }: { children: React.ReactNode }) {
  const { lang } = useLang();
  const [{ jwt = TOKEN }, setJwt] = useLocalStorage<{ jwt: string }>('phillip.session.jwt', { jwt: TOKEN });
  const [, setParams] = useSearchParams();

  /**
   * Destroys the session.
   */
  const destroySession = useCallback(() => {
    setJwt({ jwt: '' });
    // window.open(`${LOGIN_PAGE_PATH.original}?logout=true`, '_self');
  }, []);

  /**
   * Creates a contextualized action.
   */
  const getAction: GetActionMap = useMemo(() => {
    const connector = axios.create({
      timeout: REST_API_TIMEOUT,
      baseURL: REST_API_URL,
    });

    /**
     * Adds JWT to the request.
     */
    connector.interceptors.request.use((cfg) => {
      if (jwt) {
        cfg.headers.Authorization = `Bearer ${jwt}`;
      }

      cfg.headers['Accept-Language'] = lang;

      return cfg;
    });

    connector.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response?.status === 401) {
          destroySession();
        }

        return Promise.reject(error);
      }
    );

    const contextualizedActions = new Map(
      Array.from(actions.entries()).map(([name, createAction]) => [name, createAction(connector)])
    );

    return (action: ActionName) => contextualizedActions.get(action) as any;
  }, [jwt, lang]);

  // Check if there is a JWT in the URL.
  useEffect(() => {
    setParams(
      (prev) => {
        prev.delete('jwt');
        return prev;
      },
      { replace: true }
    );
  }, []);

  const value = useMemo(() => ({ jwt, getAction }), [jwt, getAction]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
