import { Flex, Box, Button, Modal } from '@mantine/core';
import { IconChevronLeft, IconDots, IconEdit, IconPlus, IconRelationOneToOne, IconTrash } from '@tabler/icons-react';
import { GetLicensePlatesResponse } from 'api/actions/get-license-plates/get-license-plates-response';
import { useApi } from 'api/api-context';
import Loader from 'components/Loader';
import MenuItem from 'components/MenuItem';
import ModalConfirmation from 'components/ModalConfirmation';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import panic from 'panic';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ECV_ADD_PAGE_PATH, HOUSE_PAGE_PATH, ECV_PAGE_PATH } from 'routes/paths';
import theme from 'theme';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';
import P1Medium from 'theme/components/Typography/P1Medium';
import P2Medium from 'theme/components/Typography/P2Medium';

type LicensePlateArray = NonNullable<GetLicensePlatesResponse['licensePlates']>;

/**
 * EČV page
 */
const Ecv: React.FC = () => {
  const { _t } = useLang();
  const [ModalOpened, setModalOpened] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);
  const navigate = useNavigate();
  const { getAction } = useApi();
  const [licensePlates, setLicensePlates] = useState<LicensePlateArray>([]);
  const [loading, setLoading] = useState(false);

  /**
   * Handle navigation button
   */
  const handleClick = () => {
    navigate(ECV_ADD_PAGE_PATH.original);
  };

  /**
   * Open  Modal
   */
  const handleOpenModal = (id: number) => {
    setSelectedItemId(id);
    setModalOpened(true);
  };

  /**
   * Close  Modal
   */
  const handleCloseModal = () => {
    setModalOpened(false);
  };

  /**
   * Open Send confirmation modal
   */
  const handleOpenConfirmationModal = () => {
    handleCloseModal();
    setConfirmationModalOpened(true);
  };

  /**
   * Close Send confirmation modal
   */
  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpened(false);
  };

  /**
   * Get selected item by ID
   */
  const getSelectedItem = (id: number | null) => {
    return licensePlates.find((item) => item.id === id) || null;
  };
  /**
   * Handle Edit Click
   */
  const handleEditClick = () => {
    if (selectedItem !== null) {
      navigate(ECV_PAGE_PATH.original + '/' + selectedItem.id);
    }
  };
  /**
   * Handle Delete Click
   */
  const handleDeleteClick = () => {
    if (selectedItem != null) {
      setLoading(true);
      const deleteLicensePlate = getAction('DeleteLicensePlate');
      deleteLicensePlate({ parameters: { licensePlateId: Number(selectedItem.id) } })
        .then(() => {
          setConfirmationModalOpened(false);
          loadLicensePlates();
          navigate(ECV_PAGE_PATH.original);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  };
  /**
   * Load License plates
   */
  const loadLicensePlates = () => {
    const getLicensePlates = getAction('GetLicensePlates');
    setLoading(true);
    getLicensePlates()
      .then(({ licensePlates }) => {
        setLicensePlates(licensePlates || []);
      })
      .catch(panic)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadLicensePlates();
  }, [getAction]);

  const selectedItem = getSelectedItem(selectedItemId);

  return (
    <SimpleLayout>
      <Box>
        <Box p="1rem">
          <Flex direction="row" align="center">
            <Link
              to={HOUSE_PAGE_PATH.original}
              style={{ textDecoration: 'none', display: 'flex', alignContent: 'center', marginBottom: '0.5rem' }}
            >
              <IconChevronLeft size="1.5rem" color={theme.colors?.gray?.[9]} />
              <P2Medium color={theme.colors?.gray?.[9]}>{_t('Back to my household')}</P2Medium>
            </Link>
          </Flex>
          <Flex gap="0.5rem">
            <IconRelationOneToOne size="2rem" color={theme.colors?.gray?.[12]} />
            <H1SemiBold color={theme.colors?.gray?.[12]}>{_t('My registration numbers')}</H1SemiBold>
          </Flex>
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box pb="8rem">
              {licensePlates.map((licenseplate) => (
                <Box
                  key={licenseplate.id}
                  onClick={() => handleOpenModal(licenseplate.id as number)}
                  style={{ cursor: 'pointer' }}
                >
                  <MenuItem
                    to=""
                    title={licenseplate.code ?? 'Default Title'}
                    description={licenseplate.note}
                    iconRight={IconDots}
                  />
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
      <Button
        variant="primary"
        size="lg"
        leftSection={<IconPlus />}
        style={{
          position: 'fixed',
          bottom: '2.5rem',
          right: '1rem',
          boxShadow: '0px 6px 12px 0px rgba(85, 31, 255, 0.30)',
        }}
        onClick={handleClick}
      >
        {_t('Add registration number')}
      </Button>

      <Modal
        variant="secondary"
        opened={ModalOpened}
        onClose={handleCloseModal}
        title={_t('Options for vehicle registration number')}
        transitionProps={{ transition: 'slide-up', duration: 400 }}
      >
        <Box>
          <Box>
            <Flex gap="0.5rem" onClick={handleEditClick}>
              <IconEdit />
              <P1Medium>{_t('Edit vehicle registration number')}</P1Medium>
            </Flex>
            <Flex gap="0.5rem" onClick={handleOpenConfirmationModal}>
              <IconTrash />
              <P1Medium>{_t('Delete vehicle registration number')}</P1Medium>
            </Flex>
          </Box>
        </Box>
      </Modal>
      <ModalConfirmation
        requestModalOpened={confirmationModalOpened}
        handleCloseRequestModal={handleCloseConfirmationModal}
        handleSubmit={handleDeleteClick}
        title={_t('Do you really want to delete registration number?')}
        cancelButtonText={_t('Cancel')}
        submitButtonText={_t('Yes, delete')}
      />
    </SimpleLayout>
  );
};

export default Ecv;
