import { Box, Card, Flex, Select, Stack } from '@mantine/core';
import { SwitchInput, SelectInput, NumberInput } from 'api/actions/get-sensor/get-sensor-response';
import { Chart, TooltipItem } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import H1Medium from 'theme/components/Typography/H1Medium';
import P2Medium from 'theme/components/Typography/P2Medium';
import P4Medium from 'theme/components/Typography/P4Medium';
import P4Regular from 'theme/components/Typography/P4Regular';

interface SensorStatisticsProps {
  calculateAverageValue: string | number;
  sensor: {
    id?: number;
    name?: string;
    mainBox?: {
      icon?: string;
      value?: number;
      unit?: string;
      headline?: string;
      subHeadline?: string;
    };
    alerts?: {
      active?: boolean;
      title?: string;
      options?: (SwitchInput | SelectInput | NumberInput)[];
    }[];
    chart?: {
      title?: string;
      views?: {
        chartType?: 'bar' | 'line';
        title?: string;
        range?: string;
        data?: { labelShort?: string; labelLong?: string; value?: number; unit?: string }[];
      }[];
    };
  };
  getRangeForSelectedTitle: (title: string) => string | undefined;
  selectedTitle: string | null;
  setSelectedTitle: (value: string | null) => void;
  titles: (string | undefined)[];
  chartData: {
    labels: (string | undefined)[];
    datasets: {
      barPercentage: number;
      label: string;
      data: number[];
      backgroundColor: string;
      hoverBackgroundColor: string;
      borderRadius: number;
      borderSkipped: boolean;
    }[];
  } | null;
  chartOptions: {
    scales: {
      x: {
        ticks: { color: string | undefined };
        grid: { drawBorder: boolean; display: boolean };
        border: { display: boolean };
      };
      y: {
        ticks: { color: string | undefined };
        grid: { drawBorder: boolean; display: boolean };
      };
    };
    plugins: {
      tooltip: {
        enabled: boolean;
        callbacks: { title: (context: TooltipItem<'bar'>[]) => string; label: (context: TooltipItem<'bar'>) => string };
      };
      legend: { display: boolean };
    };
  };
  backgroundBar: {
    id: string;
    beforeDraw: (chart: Chart<'bar'>) => void;
  };
}

/**
 * A component that displays sensor statistics.
 *
 * @prop {function} calculateAverageValue - A function that calculates the average value of the sensor.
 * @prop {object} sensor - The sensor object containing the data.
 * @prop {function} getRangeForSelectedTitle - A function that returns the range of the selected title.
 * @prop {string | null} selectedTitle - The selected title.
 * @prop {function} setSelectedTitle - A function that sets the selected title.
 * @prop {string[]} titles - An array of titles.
 * @prop {object | null} chartData - The chart data.
 * @prop {object} chartOptions - The chart options.
 * @prop {object} backgroundBar - The background bar.
 */
export default function SensorStatistics({
  calculateAverageValue,
  sensor,
  getRangeForSelectedTitle,
  selectedTitle,
  setSelectedTitle,
  titles,
  chartData,
  chartOptions,
  backgroundBar,
}: SensorStatisticsProps) {
  return (
    <Stack gap={16}>
      <P2Medium color="gray.7">Štatistika</P2Medium>
      <Card p={20} radius={10}>
        <Flex gap={12} mb={20}>
          <Box w="50%">
            <P4Regular color="gray.7">Priemerná spotreba</P4Regular>
            <H1Medium>
              {calculateAverageValue} {sensor?.mainBox?.unit}
            </H1Medium>
            <P4Medium>{getRangeForSelectedTitle(selectedTitle || '')}</P4Medium>
          </Box>
          <Box w="50%">
            <Select
              variant="tertiary"
              radius={10}
              styles={{ input: { border: '1px solid #E0E4EC', height: '40px' } }}
              value={selectedTitle}
              onChange={(value) => setSelectedTitle(value)}
              placeholder="Vyberte časový rozsah"
              data={titles
                .filter((title): title is string => title !== undefined)
                .map((title) => ({
                  value: title,
                  label: title,
                }))}
              nothingFoundMessage="Žiadne možnosti"
              searchable
            />
          </Box>
        </Flex>

        {chartData ? (
          <Bar data={chartData} options={chartOptions} plugins={[backgroundBar]} />
        ) : (
          <p>No data available</p>
        )}
      </Card>
    </Stack>
  );
}
